.main-page {
  height: calc(100vh - 120px);
  padding: 0;
}
.main-page > .el-main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0;
}
.main-page .layout-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.main-page .layout-row .layout-left {
      -webkit-box-flex: 1150;
          -ms-flex: 1150;
              flex: 1150;
}
.main-page .layout-row .layout-right {
      margin-left: 10px;
      -webkit-box-flex: 439;
          -ms-flex: 439;
              flex: 439;
}
.main-page .layout-row .layout-left,
    .main-page .layout-row .layout-right {
      height: 402px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      background-color: #fff;
      -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.06);
              box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
}
.main-page .layout-row .layout-left > div,
      .main-page .layout-row .layout-right > div {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
        overflow-y: scroll;
}
.main-page .layout-row .layout-title {
      margin-top: 18px;
      margin: 18px 20px 0 20px;
      height: 33px;
      line-height: 24px;
      font-size: var(--rootFontSize3);
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: var(--primaryBgColor);
      border-bottom: 3px solid #7f7f7f;
}
.main-page .layout-row + .layout-row {
    margin-top: 10px;
}
