.usual-flow-container {
  margin: 20px;
  overflow: hidden;
}
.usual-flow-container .usual-column {
    height: 60px;
    margin-bottom: 20px;
}
.usual-flow-container .usual-column .usual-item {
      height: 100%;
      cursor: pointer;
      font-size: var(--rootFontSize2);
      line-height: 1;
      color: var(--successTextColor);
      background: var(--successBgColor);
      border-color: var(--successBgColor);
      line-height: 60px;
      text-align: center;
      border-radius: 10px;
}
