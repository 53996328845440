.monitor-container[data-v-874c7f4e] {
  margin: 20px;
}
.monitor-container .monitor-row[data-v-874c7f4e] {
    height: 72px;
    padding-left: 9px;
    padding-top: 15px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.monitor-container .monitor-row .monitor-date[data-v-874c7f4e] {
      margin-bottom: 10px;
      height: 18px;
      line-height: 1;
      font-size: var(--rootFontSize);
      font-family: Source Han Sans CN;
      font-weight: 500;
}
.monitor-container .monitor-row .monitor-detail[data-v-874c7f4e] {
      line-height: 1;
      font-size: var(--rootFontSize);
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #222222;
}
.monitor-container .monitor-row + .monitor-row[data-v-874c7f4e] {
    margin-top: 10px;
}
.monitor-container .red-row[data-v-874c7f4e] {
    border-left: 6px solid var(--fiveThemeColor);
    background-color: #ffe2e2;
}
.monitor-container .red-row .monitor-date[data-v-874c7f4e] {
      color: var(--fiveThemeColor);
}
.monitor-container .orange-row[data-v-874c7f4e] {
    background-color: #fff3e5;
    border-left: 6px solid #fdaa27;
}
.monitor-container .orange-row .monitor-date[data-v-874c7f4e] {
      color: #fdaa27;
}
