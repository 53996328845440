.calendar-container .yellow-date {
  background-color: #FDAA27;
  color: #fff;
  border-radius: 50%;
}
.calendar-container .red-date {
  background-color: #FF6565;
  color: #fff;
  border-radius: 50%;
}
